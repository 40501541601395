<template>
  <q-dialog v-model="isOpen" :maximized="$q.screen.width < 700">
    <q-card style="min-width: 50vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Coordinates finder') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="hide"
        />
      </q-card-section>

      <q-card-section class="text-center text-caption bg-amber q-mx-lg rounded">
        {{ $t('Please select point on map') }}
      </q-card-section>

      <q-card-section class="row">
        <div class="col-12 col-md-6 q-pa-sm">
          <base-address
            :address="address"
            not-required
            full-width
            hide-additional-features
            @change="handleAddressChange"
          />

          <form-builder :schema="schema" />
        </div>

        <div class="col-12 col-md-6 q-pa-sm" style="min-height: 300px;">
          <l-map
            ref="addressMap"
            :zoom="zoom"
            :center="center"
            @dblclick="onMapClick"
          >
            <l-tile-layer
              :url="url"
              :attribution="attribution"
            />

            <l-marker
              v-if="marker"
              :lat-lng="marker"
            />
          </l-map>
        </div>
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Close')"
          @click="hide"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Save')"
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
import BaseAddress from '../clients/BaseAddress.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PlaceItemsModal',
  emits: ['submit'],
  components: {
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-marker': LMarker,
    BaseAddress
  },
  data () {
    return {
      isOpen: false,
      zoom: 13,
      center: {
        lat: 43.204666,
        lng: 27.910543
      },
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: null,
      address: {
        postcode: null,
        _embedded: {
          locality: null
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    schema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'address',
                label: this.$t('Address search'),
                field: 'notFormal',
                value: this.address.notFormal,
                wrapperStyleClasses: 'q-pa-xs',
                googleApiKey: this.appOptions.googleApiKey,
                onInput: (notFormal) => {
                  this.address.notFormal = notFormal
                },
                onPlaceChanged: (value, place) => {
                  let localAddress = {
                    postcode: place.postal_code,
                    notFormal: this.address.notFormal,
                    _embedded: {
                      locality: this.address._embedded.locality
                    }
                  }

                  if (value.x && value.y) {
                    this.setCoords(value)
                  }

                  if (!place.postal_code) {
                    return
                  }

                  localAddress.postcode = place.postal_code

                  return this.loadLocalitiesByPostcode(localAddress.postcode)
                    .then(locality => {
                      if (!locality) {
                        return
                      }

                      if (!value.x && !value.y && locality.geo) {
                        this.setCoords(this.$service.storagePlace._convertGeo(locality.geo))
                      }

                      localAddress._embedded.locality = locality
                      this.address = localAddress
                    })
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    loadLocalitiesByPostcode (value) {
      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { field: 'extId', type: 'eq', value }
        ]
      }

      return this.$service.postcode.getAll(query)
        .then(({ items }) => {
          if (items.length === 0) {
            this.addWarningNotification('No localities found!')
            return
          }

          const localities = items.reduce((acc, item) => {
            const locality = item._embedded.locality

            if (!acc.find(x => x.id === locality.id)) {
              acc.push(locality)
            }

            return acc
          }, [])

          if (localities.length === 1) {
            return localities[0]
          }
        })
    },
    setCoords({ x, y }) {
      this.center = {
        lat: x,
        lng: y
      }

      this.marker = this.center
    },
    handleAddressChange (address) {
      this.address = address

      if (this.address._embedded.locality && this.address._embedded.locality.geo) {
        this.setCoords(this.$service.storagePlace._convertGeo(this.address._embedded.locality.geo))
      }
    },
    getUserPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          this.center = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          }
        })
      }
    },
    onMapClick(value) {
      if (value.latlng) {
        this.marker = value.latlng
      }
    },
    open (defaultCoords) {
      this.isOpen = true

      this.address = {
        postcode: null,
        _embedded: {
          locality: null
        }
      }

      if (defaultCoords && defaultCoords.x && defaultCoords.y) {
        this.setCoords(defaultCoords)
      } else {
        this.getUserPosition()
      }
    },
    hide () {
      this.isOpen = false
    },
    save () {
      this.hide()
      this.$emit('submit', this.marker)
    }
  }
}
</script>
