<template>
  <form-builder :schema="schema" />
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

import _ from 'lodash'

export default {
  name: 'BaseAddress',
  emits: ['change'],
  props: {
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    fullWidth: {
      type: Boolean,
      default () {
        return false
      }
    },
    notRequired: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    address: {
      type: Object,
      default () {
        return {
          postcode: null,
          _embedded: {
            locality: null
          }
        }
      }
    }
  },
  data () {
    return {
      isFocusedPostcode: false
    }
  },
  computed: {
    schema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                isNotNumber: true,
                value: this.address.postcode,
                field: 'postcode',
                required: !this.notRequired,
                debounce: 300,
                disabled: this.disabled,
                label: this.$t('ZIP/Postal code'),
                wrapperStyleClasses: this.fullWidth
                  ? 'col-12 q-pa-xs order-last'
                  : 'col-12 col-md-4 q-pa-xs',
                onFocus: () => {
                  this.isFocusedPostcode = true
                },
                onFocusOut: () => {
                  this.isFocusedPostcode = false
                },
                onInput: postcode => {
                  this.loadLocalitiesByPostcode(postcode)
                  this.$emit('change', { ...this.address, postcode })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('City'),
                value: this.address._embedded && this.address._embedded.locality,
                disabled: this.disabled,
                field: 'locality',
                wrapperStyleClasses: this.fullWidth
                  ? 'col-12 q-pa-xs order-first'
                  : 'col-12 col-md-8 q-pa-xs',
                required: !this.notRequired,
                minimumLength: 3,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    let name = row.name

                    if (row._embedded && row._embedded.area && row._embedded.area.name) {
                      name += ', ' + row._embedded.area.name
                    }

                    if (row._embedded && row._embedded.country && row._embedded.country.name) {
                      name += ', ' + row._embedded.country.name
                    }

                    return name
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: (value) => {
                  const data = {
                    ...this.address,
                    _embedded: {
                      locality: value
                    }
                  }

                  this.$emit('change', data)
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    loadLocalitiesByPostcode (value) {
      if (value.length < 4) {
        return
      }

      const query = {
        page: 1,
        per_page: 1,
        filter: [
          { type: 'eq', field: 'extId', value }
        ]
      }

      return this.$service.postcode.getAll(query)
        .then(({ items }) => {
          if (items.length === 0) {
            this.addErrorNotification('No localities found!')
            return
          }

          const address = _.cloneDeep(this.address)

          if (!address._embedded) {
            address._embedded = {}
          }

          address._embedded.locality = items[0]._embedded.locality
          this.$emit('change', address)
        })
    }
  }
}
</script>
