<template>
  <div>
    <action-header :actions-model="headerModel" :page="page" />

    <div class="q-pa-md">
      <form-builder :schema="schema" />

        <q-toggle
                label="Bookable"
                color="light-blue-9"
                :model-value="bookable"
                @update:model-value="handleBookedChanged"
        />

      <div v-if="storagePlace && !storagePlace.id" class="row items-center">
        <div class="col-6 q-pa-xs">
          <q-input
            v-model="from"
            type="text"
            color="white"
            standout="bg-teal text-white"
            :label="$t('Range from')"
            class="text-white q-pb-sm"
            label-color="light"
          />
        </div>

        <div class="col-6 q-pa-xs">
          <q-input
            v-model="to"
            type="text"
            color="white"
            standout="bg-teal text-white"
            :label="$t('Range to')"
            class="text-white q-pb-sm"
            label-color="light"
          />
        </div>
      </div>
    </div>

    <storage-place-products
      v-if="storagePlace && storagePlace.id"
      :place="storagePlace"
    />

    <location-selector-modal
      ref="locationSelectorModal"
      @submit="handleLocation"
    />

    <sticky-bottom-header
      :saved="saved"
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />
  </div>
    <eav-modal ref="eavModal" @submit="handleEAVSubmit"/>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Services
import { InstructionsService } from '../../services/instructions.service'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'
import StoragePlaceProducts from '../../components/products/StoragePlaceProducts.vue'
import LocationSelectorModal from '../../components/modals/LocationSelectorModal.vue'
import EavModal from "../../components/modals/EavModal.vue";

export default {
  name: 'StoragePlace',
  components: {
    EavModal,
    ActionHeader,
    StoragePlaceProducts,
    LocationSelectorModal
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      types: [
        { id: 'room', name: this.$t('Room') },
        { id: 'section', name: this.$t('Section') },
        { id: 'static', name: this.$t('Static') },
        { id: 'dynamic', name: this.$t('Dynamic') },
        { id: 'employee', name: this.$t('Employee') },
        { id: 'sorting', name: this.$t('Sorting') },
        { id: 'distribution', name: this.$t('Distribution') },
        { id: 'distribution_rejected', name: this.$t('Distribution_rejected') },
        { id: 'assembly', name: this.$t('Assembly') },
        { id: 'universal', name: this.$t('Universal') },
        { id: 'pallet', name: this.$t('Pallet') },
        { id: 'defected', name: this.$t('Defected') }
      ],
      instructionsService: null,
      bookable: false,
      typesWithParent: [
        'section',
        'static',
        'dynamic',
        'sorting',
        'distribution',
        'distribution_rejected',
        'assembly',
        'universal',
        'pallet',
        'defected'
      ],
      from: null,
      to: null,
      saved:false,
    }
  },
  computed: {
    ...mapGetters([
      'storagePlace'
    ]),
    schema () {
      const wrapperStyleClasses = this.storagePlace && this.typesWithParent.includes(this.storagePlace.type)
        ? 'col-12 col-md-4 q-pa-xs'
        : 'col-12 col-md-6 q-pa-xs'

      let length = 5 - `${this.to || ''}`.length

      if (length < 0) {
        length = 0
      }

      return {
        isLoading: !this.storagePlace,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                value: this.storagePlace && this.storagePlace._embedded.warehouse,
                wrapperStyleClasses,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'type', values: ['client', 'fulfillment'] },
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: warehouse => {
                  this.updateStoragePlaceEmbedded({ warehouse })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                field: 'type',
                value: this.storagePlace && this.storagePlace.type,
                options: this.types,
                required: true,
                wrapperStyleClasses,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (type) => {
                  this.updateStoragePlace({ type: type.id })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                if: this.storagePlace && this.typesWithParent.includes(this.storagePlace.type),
                type: 'multiselect',
                label: this.$t('Parent Location'),
                wrapperStyleClasses,
                field: 'parent',
                value: this.storagePlace && this.storagePlace._embedded.parent,
                disabled: this.storagePlace && !this.storagePlace._embedded.warehouse,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.placeAlias}  (${row.code}*)`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' },
                      { type: 'eq', field: 'warehouse', value: this.storagePlace._embedded.warehouse.id }
                    ]
                  }

                  const types = [
                    'static',
                    'section',
                    'defected',
                    'universa',
                    'pallet'
                  ]

                  if (types.includes(this.storagePlace.type)) {
                    query.filter.push({ type: 'in', field: 'type', values: ['section', 'room'] })
                  }

                  return this.$service.storagePlace.getAll(query)
                },
                onChange: parent => {
                  this.updateStoragePlaceEmbedded({ parent })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'placeAlias',
                value: this.storagePlace && this.storagePlace.placeAlias,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                hint: `${this.$t('Max')} ${length} ${this.$t('characters. Please use letters and numbers only. For example, 1, ABC, AB1, XZ, 37, etc.')}`,
                max: length,
                onInput: placeAlias => {
                  this.updateStoragePlace({ placeAlias })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'X',
                field: 'x',
                value: this.storagePlace && (this.storagePlace.dimensions || {}).x,
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: x => {
                  this.updateStoragePlace({ dimensions: { ...(this.storagePlace.dimensions || {}), x } })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Y',
                value: this.storagePlace && (this.storagePlace.dimensions || {}).y,
                field: 'y',
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: y => {
                  this.updateStoragePlace({ dimensions: { ...(this.storagePlace.dimensions || {}), y } })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Z',
                value: this.storagePlace && (this.storagePlace.dimensions || {}).z,
                field: 'z',
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: z => {
                  this.updateStoragePlace({ dimensions: { ...(this.storagePlace.dimensions || {}), z } })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Volume'),
                field: 'volume',
                value: this.storagePlace && this.storagePlace.volume,
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: volume => {
                  this.updateStoragePlace({ volume })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Longitude'),
                field: 'geoArray.x',
                value: this.storagePlace && this.storagePlace.geoArray.x,
                wrapperStyleClasses: 'col-6 col-md-5 q-pa-xs',
                onChange: x => {
                  this.updateStoragePlace({ geoArray: { ...this.storagePlace.geoArray, x } })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Latitude'),
                field: 'geoArray.y',
                value: this.storagePlace && this.storagePlace.geoArray.y,
                wrapperStyleClasses: 'col-6 col-md-5 q-pa-xs',
                onChange: y => {
                  this.updateStoragePlace({ geoArray: { ...this.storagePlace.geoArray, y } })
                  this.hasChange = true
                  this.saved = false
                }
              },
              {
                type: 'btn',
                label: this.$t('Select location'),
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs',
                size: 'lg',
                btnStyleClasses: 'fit',
                onClick: () => {
                  this.$refs.locationSelectorModal.open(this.storagePlace.geoArray)
                }
              }
            ]
          }
        ]
      }
    },
    page () {
      return {
        id: this.storagePlace && this.storagePlace.id,
        name: this.storagePlace && this.storagePlace.id
          ? this.$t('Location')
          : this.$t('New location')
      }
    },
    headerModel () {
      if (!this.storagePlace) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'col-sm-1 q-pr-md hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-3 mobile-title text-white',
          options: [
            {
              id: 'title',
              type: 'text',
              value: this.storagePlace.id ? this.$t('Location ID: ') + this.storagePlace.id : this.$t('New Location')
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-sm-4 row text-center row justify-center',
          options: [
            {
              id: 'print',
              wrapperClassName: 'q-pa-xs',
              wrapperStyle: 'max-width: 150px;',
              type: 'button',
              icon: 'qr_code',
              disabled: !this.storagePlace || !this.storagePlace.id,
              variant: 'light',
              label: this.$t('Label'),
              onClick: () => {
                const query = {
                  filter: [
                    { type: 'eq', field: 'id', value: this.storagePlace.id }
                  ]
                }
                this.$service.storagePlace.printAll(query)
                  .then(({ text }) => {
                    this.$service.printer.print(text, 'LABEL', undefined, true)
                  })
              }
            },
            {
              id: 'eav',
              wrapperClassName: 'q-pa-xs',
              type: 'button',
              wrapperStyle: 'max-width: 150px;',
              label: this.$t('EAV'),
              hasIcon: true,
              disabled: !this.storagePlace || !this.storagePlace.id,
              variant: 'light',
              onClick: () => {
                this.$refs.eavModal.open(this.storagePlace.eav, { entityClass: this.$entities.Orderadmin_Storage_Entity_AbstractPlace})
              }
            },
          ]
        }
      ]
    }
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))

    if (!this.$route.params.id) {
      this.setNewStoragePlace()
    }

    if (this.$route.params.id && !this.storagePlace) {
      return this.loadStoragePlace(this.$route.params.id)
        .then(() => {
          this.bookable = this.storagePlace.bookable
          if (!this.storagePlace.dimensions) {
            this.fixDimensions()
          }
        })
    }

    if (!this.storagePlace.dimensions) {
      this.fixDimensions()
    }
  },
  unmounted () {
    this.setStoragePlace(null)
  },
  methods: {
    ...mapActions([
      'loadStoragePlace',
      'saveStoragePlace',
      'loadWarehouses',
      'loadStoragePlaces'
    ]),
    ...mapMutations([
      'setStoragePlace',
      'setNewStoragePlace',
      'updateStoragePlace',
      'addErrorNotification',
      'upsertStoragePlace',
      'insertStoragePlaces',
      'updateStoragePlaceEmbedded'
    ]),
    handleEAVSubmit (eav) {
      this.updateStoragePlace({ eav })
      this.hasChange = true
    },
    handleBookedChanged () {
      this.bookable = !this.bookable
      if(this.bookable === true) {
        this.updateStoragePlace({ bookable: 1 })
        this.hasChange = true
        this.saved = false
      } else {
        this.updateStoragePlace({ bookable: 0 })
        this.hasChange = true
        this.saved = false
      }
    },
    handleLocation (value) {
      if (!value) {
        this.updateStoragePlace({ geoArray: { x: null, y: null } })
        this.hasChange = true
        this.saved = false
        return
      }

      const geoArray = {
        x: value.lat,
        y: value.lng
      }

      this.updateStoragePlace({ geoArray })
      this.hasChange = true
      this.saved = false
    },
    fixDimensions () {
      this.updateStoragePlace({
        dimensions: {
          x: 0,
          y: 0,
          z: 0
        }
      })
    },
    refresh () {
      this.loadStoragePlace(this.$route.params.id)
    },
    save () {
      let length = 5 - `${this.to || ''}`.length

      if (this.storagePlace.placeAlias.length > length) {
        this.addErrorNotification(this.$t('Please use maximum') + ` ${length} ` + this.$t('characters for location name.'))
        return
      }

      if (!this.typesWithParent.includes(this.storagePlace.type) && this.storagePlace._embedded.parent) {
        this.updateStoragePlace({
          _embedded: {
            ...this.storagePlace._embedded,
            parent: null
          }
        })
      }

      if (this.typesWithParent.includes(this.storagePlace.type) && !this.storagePlace._embedded.parent) {
        this.addErrorNotification('You must set parent location!')
        return
      }

      if (!this.storagePlace.id && this.from !== null && this.to !== null) {
        const from = this.$utils.convertToNumber(this.from)
        const to = this.$utils.convertToNumber(this.to)

        if (from.value > to.value) {
          this.addErrorNotification('Range from can\'t be bigger than range to!')
          return
        }

        const names = this.$utils.generateCodes(this.from, this.to)
          .map(x => `${this.storagePlace.placeAlias || ''} ${x}`)

        this.isSaveLoading = true
        return this.savePlaces(names, [])
          .then(places => {
            this.insertStoragePlaces(places)
            this.handleBack()
            return places
          })
          .finally(() => {
            this.isSaveLoading = false
          })
      }

      this.isSaveLoading = true
      this.saved = true
      this.changeSaved = true
      return this.saveStoragePlace()
        .then(place => {
          this.upsertStoragePlace(place)
          return [place]
        })
        .finally(() => {
          this.isSaveLoading = false
        })
        
    },
    savePlaces (names, results = []) {
      if (names.length <= 0) {
        return Promise.resolve(results)
      }

      this.updateStoragePlace({ placeAlias: names[0] })

      return this.saveStoragePlace()
        .then(item => {
          results.push(item)
          return this.savePlaces(names.slice(1), results)
        })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
